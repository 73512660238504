<template>
	<div>
		<myheader></myheader>
		<div class="banimg">
			<!-- <img :src="view.attachment" alt=""> -->
			<img src="http://yzhs-1253640091.cos.ap-shanghai.myqcloud.com/5f944fa2cbc245c4398980473ce207a8" alt="">
		</div>
		<div style="box-shadow: 0px 2px 4px 0px rgba(0,0,0,0.05); height: 75px;">
			<div class="mainimg">
				<div class="header flex-between">
				   <div class="tou">
					   <img :src="view.avatar">
				   </div>
				   <div class="content">
					  <div class="c1">{{view.title}}</div>
					  <div class="c2 flex-between">
						  <p>
							<span style="color: #FF7C00;">{{view.account}}</span>
							<span style="color: #ccc;">|</span>
							<img :src="vipimg[view.level-1]" width="70px">
							<span >{{view.create_time}}</span>
						  </p>
						  <p>
							<img src="../../assets/shot/read.png" alt="">
							<span>{{view.look_number}}人阅读</span>
							<img src="../../assets/other/collection.png" alt="">
							<span>{{view.collectionCount}}人收藏</span>
						  </p>
						  <div class="pic">
							<div @click="sc(view.is_collection)">
								<img src="../../assets/2_p.png"  v-if="view.is_collection==1">
								<img src="../../assets/2.png"  v-if="view.is_collection==0">
								<p>收藏</p>
							</div>
							<div>
								<img src="../../assets/shot/fx2.png" alt="">
								<p>分享</p>
							</div>
						  </div>
					  </div>
				   </div>
				</div>

			</div>
		</div>

		<div class="imginfo">
			<div class="ileft" v-html="description">
				
			</div>
			<div class="cright">
				<h3>推荐游记</h3>
				
				<div v-for="(item,i) in activelist" :key="i" @click="toactive(item.id)" class="img">
					<img :src="item.attachment" alt="">
					<div class="bg"></div>
					<p>{{item.title}}</p>
				</div>
				<!-- 分页 -->
				<div id="page">
					
					<el-pagination
					small
					layout="prev, pager, next"
					:total="total2"
					@current-change="handleCurrentChange2"
					:page-size="form2.pageSize"
					>
					</el-pagination>
				</div>
			</div>
		</div>

		<myfooter></myfooter>
		<myright></myright>
	</div>

</template>

<script>
import myheader from '@/components/header.vue'
import myfooter from '@/components/footer.vue'
import myright from '@/components/right.vue'

import {takeCollectionList} from "@/api/active"
import {takeViewAPI,collectionTakeDelAPI } from "@/api/trivialShot"
import {trvaelArticleAPI } from "@/api/index"
export default {

  components: {
    myheader, myfooter, myright
  },
  data () {
    return {
		view:{},
		form2:{
			pageSize:6,
			page:1,
			pid:1
		},
		total2:1,
		activelist:[],
		description:"",
		id:"",
		vipimg:[] 
    }
  },
  created () {
	 let id=this.$route.query.id
	 this.id=id
	  this.getview(id)
	  this.getactivelist()
	  this.getImage()
  },
  methods: {
	  getImage() {
		for (let index = 1; index < 6; index++) {
			this.vipimg.push(require('../../assets/vip/vip' +index+ '.png'))			
		}
	  },
	  sc(n){
		  var data={
			  tid:this.id,
			  status:n==1 ? 0 : 1,
			  type:2
		  }
		  collectionTakeDelAPI(data).then(res=>{
			  this.$message.success(n==1 ? "取消收藏" : "收藏"+"成功")
			  
			  this.view.is_collection=n==1 ? 0 : 1
			if (n==1) {
			this.view.collectionCount--
			} else {
			this.view.collectionCount++
			}
		  })	 
		 },
	  toactive(id){
		 this.$router.push({ path: '/imginfo',query:{id} })
		 this.getview(id)
	
	},
	getview(id){
		  takeViewAPI({id}).then(res=>{
			  this.view=res.View
			  this.description = res.View.content.replace(/<img/gi,
						'<img style="max-width:100%;height:auto;display:block" ');
		  })
	},
	getactivelist(){

		trvaelArticleAPI().then(res=>{
			this.activelist=res.cateList
			// this.total2=res.rowsCount
		})
	},
	handleCurrentChange2: function(currentPage){
           this.form2.page=currentPage
            this.getactivelist()
    },
  }
}
</script>

<style lang="scss" scoped="scoped">
.banimg{
	img{
		width: 100%;
	}
	height: 380px;
	overflow: hidden;
	min-width: 1200px;
}
.mainimg{
	width: 1200px;
	min-height: 1000px;
	margin: 0 auto;
	position: relative;
	.header{
		position: absolute;
		top: -60px;
		left: 0;
		width: 1200px;

		.tou{
			width: 126px;
			height: 126px;
			border-radius: 50%;
			background-color: #fff;
			display: flex;
			justify-content: center;
			align-items: center;
			img{
				width: 120px;
				height: 120px;
				border-radius: 50%;
			}
		}
		.content{
			flex: 1;
			margin-left: 20px;
			.c1{
				font-size: 22px;
				font-weight: 500;
				color: #000;
				line-height: 30px;
			}
			.c2{
				margin-top: 30px;
				>p{
					display: flex;
					justify-content: center;
					align-items: center;
					span{
						margin-right: 20px;
						color: #333;
					}
					img{
						margin-right: 10px;
					}
				}
			}
		}
		.pic{
			display: flex;
			width: 130px;
			justify-content: space-around;
			img{
				width: 30px;
				height: 30px;
			}
			p{
				font-size: 12px;
				text-align: center;
			}
		}
	}

}
.imginfo{
	display: flex;
	width: 1200px;
	margin:  20px auto;
	.ileft{
		width: 930px;
		padding: 0 15px;
		min-height: 400px;
		p{
			line-height: 30px;
		}
		img{
			width: 100%;
		}
	}
	.iright{
		margin-left: 30px;
		h3{
			font-size: 18px;
			font-weight: 600;
			color: #333333;
			line-height: 45px;

		}
		.activelist{

			.item{
				margin-top: 20px;
				.img{
					width: 240px;
					height: 136px;
					overflow: hidden;

					img{
						width: 240px;
						min-height: 136px;
						border-radius: 5px 5px 0 0;
					}
				}
				.text{
					background: #f5f5f5;
					padding: 5px 10px;
				}
				p{
					font-size: 12px;
					font-family: PingFangSC, PingFangSC-Medium;
					font-weight: 500;
					text-align: left;
					color: #333333;
					line-height: 24px;
					display: flex;
					align-items: center;
					img{
						width: 20px;
						height: 20px;
						border-radius: 50%;
					}
					span{
						color: #FF7C00;
						margin-left: 10px;
					}
				}

			}
		}
	}
}

.cright{
	position: relative;
	z-index: 2;
	width: 240px;
	h3{
		font-size: 16px;
		font-weight: 600;
		color: #333333;
		line-height: 35px;
		letter-spacing: 1px;
	}
	
	.img{
		margin-top: 10px;
		border-radius: 4px;
		img{
			width: 240px;
			margin-top: 10px;
			border-radius: 4px;
		}
		p{
			text-align: center;
		}
		
	}
	.page{
		width: 110px;

		display: flex;
		height: 25px;
		margin: 25px auto 15px;
		p{
			margin: 0 8px;
			font-size: 14px;
			text-align: center;
			color: #333333;
			line-height: 25px;
		}
		img{
			height: 25px;
			width: 25px;
			margin: 0;
		}
	}
}
</style>
<style lang="scss">
#page{
	margin: 80px auto 30px;
	display: flex;
	justify-content: center;

	.el-pager .active{
		background-color: #ff7c00 !important;
	}
	.el-pager li.active{
		color: #fff;
	}
	.number:hover{
		color: #ff7c00;
	}
}
</style>